import { type FunctionComponent, useState } from 'react';
import { type ProductSortEnum } from '../../../__generated__/graphql-client-types';
import { DEFAULT_PRODUCT_SORT, SEARCH_SORT_OPTIONS_MOBILE, SORT_BY_MAP } from '../../../constants/search';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useSearchResults, type UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { ClickableElement } from '../../buttons';
import { RadioButtonGroup } from '../../inputs/radio-button-group/radio-button-group.component';
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '../../svg/icons.component';

export type SortByDropdownProps = {
	useResults?: () => UseSearchResultsPayload;
};

export const SortByDropdown: FunctionComponent<SortByDropdownProps> = ({ useResults = useSearchResults }) => {
	const { sortBy, setSortBy } = useResults();
	const [isExpanded, setIsExpanded] = useState(false);

	const sortChanged = (value: ProductSortEnum) => {
		setSortBy(value);
	};

	const handleSortByPress = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsExpanded(!isExpanded);
	};

	const chevronStyle = 'fw2 mt1';
	const titleClassName = 'mv0 ml2 lh-title f5 truncate fw7';

	return (
		<div>
			<div className="mt0 pt4 pb0 ph2 items-start flex justify-between-l">
				<div className="w-90-ns w-100 flex items-start pointer bg-theme-white pb0">
					<ClickableElement
						className="flex w-100 justify-start items-start justify-end-l flex-row-reverse-l"
						ariaLabel="Sort By"
						onClick={handleSortByPress}
						onKeyDown={handleKeys(['Enter', ' '], handleSortByPress)}>
						<>
							<div className="w-100 self-end">
								<h2 className={`${titleClassName}`}>Sort By</h2>
								<h3
									className={`f6 pt2 ${titleClassName} ${
										sortBy !== DEFAULT_PRODUCT_SORT ? 'theme-primary' : 'theme-secondary'
									}`}>
									{SORT_BY_MAP[sortBy]}
								</h3>
							</div>
							{isExpanded ? (
								<div className="f4">
									<ChevronDownIcon className={`${chevronStyle} dn db-l`} />
									<ChevronUpIcon className={`${chevronStyle} db dn-l`} />
								</div>
							) : (
								<div className="f4">
									<ChevronRightIcon className={`${chevronStyle} dn db-l`} />
									<ChevronDownIcon className={`${chevronStyle} db dn-l`} />
								</div>
							)}
						</>
					</ClickableElement>
				</div>
			</div>
			{isExpanded && (
				<div className="pa3 bg-theme-white">
					<RadioButtonGroup
						groupName="sortSelect"
						options={SEARCH_SORT_OPTIONS_MOBILE}
						onSelectionChanged={sortChanged}
						defaultValue={sortBy}
						data-testid="sort-select"
						automationHook="product-sort-by"
					/>
				</div>
			)}
		</div>
	);
};
