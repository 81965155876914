import { type FunctionComponent, useEffect, useReducer, useState } from 'react';
import { FACET_GROUP_FILTER_SHOW_SIZE, QUICKSHIP_FACET_DISPLAY_VALUE, RANGE_FACET_VALUE_SEPARATOR } from '../../../constants/search';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { formatNumber, generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { trackSearchEvent } from '../../../helpers/search-helper/search-analytics.helper';
import {
	facetGroupIsQuickShip,
	findSelectedFacet,
	getFacetDisplayValue,
	getFacetGroupDisplayName,
	getRangeFacetValues,
	getUnitAffixes,
	isRangeFacetGroup
} from '../../../helpers/search-helper/search-helper';
import {
	type UseFacetGroupResults,
	type UseSearchResultsPayload,
	useFacetGroupResults,
	useSearchResults
} from '../../../hooks/apollo/search/search.hooks';
import { type FacetGroup as FacetGroupType, type FacetResult, type FacetValueBase, type SelectedFacet } from '../../../types/search.types';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';
import { Popover } from '../../common-components/popover/popover.component';
import { DynamicTextInput } from '../../inputs';
import { QuickShip } from '../../quick-ship/quick-ship.component';
import { CheckIcon, ChevronUpIcon, CloseIcon, InfoIcon, SearchIcon, TuneIcon } from '../../svg/icons.component';
import { Range } from '../range/range.component';
import { SortByDropdown } from '../sort-by-dropdown/sort-by-dropdown.component';
import { facetListReducer, facetListReducerInitializer } from './facet-list-reducer';
import {
	checkbox,
	checkboxFill,
	checkboxSelected,
	expandIcon,
	facetTooltip,
	facetScrollableValues,
	facetsWrapper,
	facetsWrapperExpanded
} from './facet-list.css';

const MAX_SUBHEADING_VALUES = 3;
const MIN_FACETS_FOR_GROUP_SCROLL = 7;

type SelectedFacetsSubHeadingProps = {
	group: FacetGroupType;
	facets: SelectedFacet[];
	capitalizeValues: boolean;
};

const SelectedFacetsSubHeading: FunctionComponent<SelectedFacetsSubHeadingProps> = ({ group, facets, capitalizeValues }) => {
	if (!facets.length) {
		return null;
	}
	const { isRangeGroup, selectedMinimum, selectedMaximum, isSelected } = getRangeFacetValues(group, facets);
	if (isRangeGroup && !isSelected) {
		return null;
	}
	const capitalizeClass = capitalizeValues && !isRangeGroup ? 'ttc' : '';

	const facetsToDisplay = isRangeGroup
		? [{ facetId: facets[0].facetId, value: `${selectedMinimum}${RANGE_FACET_VALUE_SEPARATOR}${selectedMaximum}` }]
		: facets.slice(0, MAX_SUBHEADING_VALUES);
	const facetDisplayValues = facetsToDisplay.map((facet) => getFacetDisplayValue(facet, group));
	if (!isRangeGroup && facets.length > facetDisplayValues.length) {
		facetDisplayValues.push(`+${facets.length - facetDisplayValues.length}`);
	}

	return (
		<div className="fade-in mt2 fw3 w-100" data-testid="selectedFacetSubHeading">
			<div className={`f6 fw4 theme-primary-dark ${capitalizeClass}`}>{facetDisplayValues.join(', ')}</div>
		</div>
	);
};

export type FacetsFilterProps = {
	group: FacetGroupType;
	value: string;
	onChange: (newValue: string) => void;
};

const FacetsFilter: FunctionComponent<FacetsFilterProps> = ({ group, value, onChange }) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
	const iconClass = 'f5 theme-grey ph2 input fade-in';
	return (
		<div className="mh2 mb2">
			<DynamicTextInput
				value={value}
				size="DEFAULT"
				onChange={onInputChange}
				placeholder={`Find in ${group.name}`}
				ariaLabel={`Filter ${group.name} list`}
				className="f6"
				icon={value ? <CloseIcon className={iconClass} /> : <SearchIcon className={iconClass} />}
				padIcon={false}
				iconOnClick={() => (value ? onChange('') : undefined)}
				automationHook={group.name}
			/>
		</div>
	);
};

export const FacetCheckBox: FunctionComponent<{
	isSelected: boolean;
	isAutoApplied?: boolean;
	label: string | React.JSX.Element;
	count: number;
}> = ({ isSelected, isAutoApplied, label, count }) => {
	return (
		<>
			<div data-testid="facetCheckbox" className={`${checkbox} ${isSelected ? checkboxSelected : ''} flex-shrink-0`}>
				<div className={checkboxFill}>
					<CheckIcon className="db f5 theme-white" />
				</div>
			</div>
			<div className={`flex-grow-1 f5 lh-title ${isAutoApplied ? 'theme-grey' : ''}`}>{label}</div>
			<span className="flex-shrink-0 f6 lh-title theme-grey">({formatNumber(count)})</span>
		</>
	);
};

export type FacetProps = {
	onFacetChange: (facet: any) => void;
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	facet: FacetResult;
};

const Facet: FunctionComponent<FacetProps> = ({ onFacetChange, group, selectedFacets, facet }) => {
	const isRangeGroup = isRangeFacetGroup(group);
	if (isRangeGroup) {
		const onRangeFacetChange = (lowRange: string, highRange: string) => {
			// Combine high and low values into one string using RANGE_FACET_VALUE_SEPARATOR for Search Hook methods
			const rangeValue = lowRange && highRange ? `${lowRange}${RANGE_FACET_VALUE_SEPARATOR}${highRange}` : '';
			onFacetChange({ facetId: facet.facetId, value: rangeValue });
		};
		const rangeValues = getRangeFacetValues(group, selectedFacets);
		const { unitPrefix, unitSuffix } = getUnitAffixes(group);
		return (
			<Range
				min={rangeValues.rangeMinimum}
				max={rangeValues.rangeMaximum}
				selectedMin={rangeValues.selectedMinimum}
				selectedMax={rangeValues.selectedMaximum}
				groupName={group.name}
				isSelected={rangeValues.isSelected}
				unitPrefix={unitPrefix}
				unitSuffix={unitSuffix}
				onRangeSubmit={onRangeFacetChange}
			/>
		);
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const label = isQuickShip ? (
		<QuickShip message={QUICKSHIP_FACET_DISPLAY_VALUE} messageClasses="" />
	) : (
		getFacetDisplayValue(facet, group)
	);
	const selectedFacet = selectedFacets?.find(
		(item) => item.facetId === facet.facetId && item.value.toLowerCase() === facet.value.toLowerCase()
	);
	const isSelected = Boolean(selectedFacet);
	const isAutoApplied = Boolean(selectedFacet?.autoApplied);
	const onChange = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onFacetChange(facet);
	};

	return (
		<div
			role="button"
			tabIndex={0}
			aria-disabled={isAutoApplied}
			className="pointer overflow-hidden hover-bg-theme-grey-lighter flex items-start ga2 ph2 input pv2-l"
			onClick={onChange}
			onKeyPress={handleKeys(['Enter', ' '], onChange)}
			data-testid="searchFacet">
			<FacetCheckBox isSelected={isSelected} isAutoApplied={isAutoApplied} label={label} count={facet.count} />
		</div>
	);
};

export type FacetsProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	onFacetChange: (facet: FacetValueBase) => void;
};

const Facets: FunctionComponent<FacetsProps> = ({ group, selectedFacets, onFacetChange }) => {
	const [filterValue, setFilterValue] = useState('');
	const showFilter = group.facets.length > FACET_GROUP_FILTER_SHOW_SIZE;
	let facets = group.facets,
		onFilterChange;
	if (showFilter) {
		onFilterChange = (newValue: string) => setFilterValue(newValue);
		const cleanedFilterValue = filterValue.trim().toLowerCase();
		if (cleanedFilterValue) {
			facets = facets.filter((facet) => facet.value.toLowerCase().includes(cleanedFilterValue));
		}
	}

	const scrollClasses = group.facets.length >= MIN_FACETS_FOR_GROUP_SCROLL ? `overflow-y-auto-l ${facetScrollableValues}` : '';

	return (
		<>
			{showFilter && <FacetsFilter group={group} value={filterValue} onChange={onFilterChange} />}
			<div className={`${scrollClasses} pb2`} data-automation="facet-range" data-testid={`${group.name} values`}>
				{facets.map((facet) => (
					<Facet
						key={`${facet.facetId}-${facet.value}`}
						facet={facet}
						group={group}
						onFacetChange={onFacetChange}
						selectedFacets={selectedFacets}
					/>
				))}
				{facets.length === 0 && (
					<div className="flex items-center pa2 overflow-hidden">
						<div className="f5 ml2 lh-title">No {filterValue ? 'matches found' : 'filters available'}.</div>
					</div>
				)}
			</div>
		</>
	);
};

export type FacetGroupInfoProps = {
	description: string;
	term: string;
};

const FacetGroupInfo: FunctionComponent<FacetGroupInfoProps> = ({ description, term }) => {
	const [isOpen, setIsOpen] = useState(false);
	const helpTitle = `Get help with ${term}`;
	const eventBlocker = (event: React.SyntheticEvent) => event.stopPropagation();
	return (
		// Stop propagation of clicks in the popover to prevent opening/closing the facet group.
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div className="dn db-l pl1" onClick={eventBlocker} onKeyDown={handleKeys(['Enter', ' '], eventBlocker)}>
			<Popover
				isVisible={isOpen}
				setIsVisible={setIsOpen}
				direction="bottom"
				toggleElement={
					<span title={helpTitle}>
						<InfoIcon className="pointer db theme-grey" />
					</span>
				}
				parentElementClassName="relative flex">
				<PanelComponent
					headingContent={helpTitle}
					containerClassName={`${facetTooltip} pa3`}
					className="f7"
					headerClassName="f6 bg-theme-grey-lighter">
					<div style={{ minWidth: '20rem' }} dangerouslySetInnerHTML={{ __html: description }}></div>
				</PanelComponent>
			</Popover>
		</div>
	);
};

export type FacetGroupProps = {
	group: FacetGroupType;
	selectedFacets?: SelectedFacet[];
	isExpanded: boolean;
	onClick: (group: FacetGroupType) => void;
	useResults: () => UseSearchResultsPayload;
	useSearchFacetGroupResults: (facetGroupId: string) => UseFacetGroupResults;
};

const FacetGroup: FunctionComponent<FacetGroupProps> = ({
	group,
	selectedFacets = [],
	isExpanded,
	onClick,
	useResults,
	useSearchFacetGroupResults
}) => {
	const { query, addFacet, removeFacet, categoryId, createGTMEvent, results } = useResults();
	const { loadFacetGroup, facetGroup: fullFacetGroup, called: loadFacetGroupCalled } = useSearchFacetGroupResults(group.id);
	const [renderFacets, setRenderFacets] = useState(isExpanded);
	const isSolr = results?.searchEngine === 'SOLR';

	// Once we render a facet list we keep it in the DOM. This allows smooth expand and collapse.
	useEffect(() => {
		if (isExpanded && !renderFacets) {
			setRenderFacets(true);
		}
	}, [isExpanded, renderFacets]);

	// Fetch full facet group if expanded and more available.
	if (isExpanded && group.metadata.hasMoreFacets && !loadFacetGroupCalled) {
		loadFacetGroup();
	} else if (fullFacetGroup) {
		group = { ...fullFacetGroup, range: group.range, info: group.info, unitPrefix: group.unitPrefix, unitSuffix: group.unitSuffix };
	}

	const isQuickShip = facetGroupIsQuickShip(group);
	const isRangeGroup = isRangeFacetGroup(group);
	const facetGroupName = getFacetGroupDisplayName(group);

	const handleFacetChange = (facet: FacetValueBase) => {
		const selectedFacet = findSelectedFacet(selectedFacets, facet, { isQuickShip, isRangeGroup });
		const pageQuery = categoryId ? `c${categoryId}` : query;
		if (selectedFacet?.autoApplied) {
			return;
		}

		// Range facet behaves differently. Don't try to remove if selected.
		if (!facet.value || (selectedFacet && !isRangeGroup)) {
			removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: selectedFacet?.sourceFacetId });
		} else {
			addFacet({ id: facet.facetId, value: facet.value });
		}

		void trackSearchEvent(
			createGTMEvent(TrackedEvent.FACET_INTERACTION, {
				query: pageQuery,
				facet,
				group,
				applied: !selectedFacet
			})
		);
	};

	const handleFacetGroupPress = (e: React.SyntheticEvent) => {
		e.preventDefault();
		onClick(group);
	};

	const facetsWrapperClassName = `${facetsWrapper} ${isExpanded ? facetsWrapperExpanded : ''}`;
	const inertProp = isExpanded ? null : { inert: '' }; // Prevent hidden facets from receiving events, focus, etc.

	return (
		<div className="bb b--theme-grey-light mh1 mh0-l">
			<div data-automation={generateDataSelector('facet', facetGroupName)}>
				<div className="flex w-100 items-center">
					<div
						className="w-100 pointer pv4 pv3-l ph2"
						role="button"
						tabIndex={0}
						onClick={handleFacetGroupPress}
						onKeyDown={handleKeys(['Enter', ' '], handleFacetGroupPress)}
						data-testid="searchFacetGroup">
						<div className="flex items-center gc1">
							<div className="lh-solid fw6">{facetGroupName}</div>
							{group.info && <FacetGroupInfo description={group.info.description} term={group.info.term} />}
							<ChevronUpIcon className={`ml-auto flex-shrink-0 ${expandIcon} ${isExpanded ? '' : 'rotate-180'}`} />
						</div>
						{!(isQuickShip && isExpanded) && (
							<SelectedFacetsSubHeading group={group} facets={selectedFacets} capitalizeValues={isSolr} />
						)}
					</div>
				</div>
			</div>
			<div className={facetsWrapperClassName} {...inertProp} data-testid="searchFacetGroupValues">
				{(isExpanded || renderFacets) && <Facets group={group} selectedFacets={selectedFacets} onFacetChange={handleFacetChange} />}
			</div>
		</div>
	);
};

export type FacetListProps = {
	hideDesktopHeader?: boolean;
	includeSortBy?: boolean;
	useResults?: () => UseSearchResultsPayload;
	useSearchFacetGroupResults?: (facetGroupId: string) => UseFacetGroupResults;
};

export const FacetList: FunctionComponent<FacetListProps> = ({
	hideDesktopHeader = false,
	includeSortBy = false,
	useResults = useSearchResults,
	useSearchFacetGroupResults = useFacetGroupResults
}) => {
	const { results, loading: resultsLoading, createGTMEvent, request } = useResults();
	const [state, dispatch] = useReducer(
		facetListReducer,
		{ searchResults: results, loading: resultsLoading },
		facetListReducerInitializer
	);
	useEffect(() => {
		if (resultsLoading) {
			dispatch({ type: 'loadingData' });
		} else {
			dispatch({ type: 'loadData', searchResults: results });
		}
	}, [resultsLoading, results]);

	const { facetGroups, selectedFacetGroups, groupExpansion, loading } = state;
	if (facetGroups.length === 0) {
		return null;
	}

	const someFacetsGroupsExpanded = Object.values(groupExpansion).some((value) => value);
	const toggleFacetGroupExpanded = (group: FacetGroupType) => {
		const event = groupExpansion[group.id] ? TrackedEvent.COLLAPSE_FACET_GROUP : TrackedEvent.EXPAND_FACET_GROUP;
		void trackSearchEvent(createGTMEvent(event, { group }));
		dispatch({ type: 'toggleGroupExpanded', id: group.id });
	};
	const toggleAllFacetGroupsExpanded = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch({ type: someFacetsGroupsExpanded ? 'collapseAllGroups' : 'expandAllGroups' });
	};

	return (
		<section>
			{!hideDesktopHeader && (
				<div className="bb b--theme-grey-light f4 fw6 flex-ns items-center justify-between pb2 pl2 pr1 lh-solid dn fw3 ma0">
					<TuneIcon />
					<span className="ml2 flex-grow-1">Filters</span>
					<span
						onClick={toggleAllFacetGroupsExpanded}
						onKeyDown={handleKeys(['Enter', ' '], toggleAllFacetGroupsExpanded)}
						className="f5 fw4 input ph2 theme-primary-dark pointer underline-hover"
						role="button"
						tabIndex={0}>
						{someFacetsGroupsExpanded ? 'Collapse' : 'Expand'} All
					</span>
				</div>
			)}
			<div>
				{includeSortBy && <SortByDropdown useResults={useResults} />}
				{facetGroups.map((group) => {
					let selectedFacets = selectedFacetGroups.find((selectedGroup) => group.id === selectedGroup.id)?.facets;
					if (loading) {
						// Use request facets while loading so selected values update as the user clicks.
						const groupFacetId = group.facets.length ? group.facets[0].facetId : null;
						selectedFacets = request.facetFilter
							.filter((rf) => rf.id === groupFacetId)
							.map((rf) => ({ facetId: rf.id, value: rf.value, autoApplied: false, sourceFacetId: null }));
					}
					return (
						<FacetGroup
							group={group}
							useResults={useResults}
							useSearchFacetGroupResults={useSearchFacetGroupResults}
							selectedFacets={selectedFacets}
							onClick={toggleFacetGroupExpanded}
							isExpanded={groupExpansion[group.id]}
							key={group.id}
						/>
					);
				})}
			</div>
		</section>
	);
};
