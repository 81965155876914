import type { ProductSearchInput, ProductViewEnum } from '../../../__generated__/graphql-client-types';
import { type ProductSearchResult } from '../../../types/search.types';
import { FindingMethodSubtype, FindingMethodType, TrackedEvent, type TrackedEventCase } from '../event-types';
import type { GTMEventWrapper, GTMCustomEvent, GTMFindingMethodEvent } from './gtm-types';
import { wrapGTMEvent, generateTrackingSearchTerm } from './gtm-utils.helper';

/**
 * Builds a GTM event for a search event
 */
export const buildGTMSearch = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	internal = false,
	nonstock = false,
	newSearch = false,
	previousQuery?: string,
	searchFlagged = false
): GTMEventWrapper<GTMCustomEvent> => {
	const filters = searchRequest.facetFilter?.map((f) => [f.id, f.rangeBound, f.value].filter((s) => s).join(':')).sort();
	return {
		...wrapGTMEvent(eventType, useCase, null, generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')),
		categoryId: searchRequest.categoryId,
		collectionName: searchRequest.collectionName,
		manufacturer: searchRequest.manufacturer,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		internal,
		filterCount: searchRequest.facetFilter?.length ?? 0,
		filters,
		nonstock,
		newSearch,
		...(newSearch && previousQuery && { 'previous-search-term': previousQuery }),
		blocked: searchFlagged,
		queryStep: searchResults?.queryStep
	};
};

type FindingMethoeEventBuilder = () => GTMFindingMethodEvent;
export const buildGTMFindingMethod =
	(type: FindingMethodType, subType: FindingMethodSubtype): FindingMethoeEventBuilder =>
	() => ({
		event: TrackedEvent.FINDING_METHODS,
		findingMethod: type,
		findingSubType: subType
	});

// specific search finding method builders
export const buildGTMFindingMethodSAYTTerm = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_TERM);
export const buildGTMFindingMethodSAYTProduct = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_PRODUCT);
export const buildGTMFindingMethodRecentSearchTerm = buildGTMFindingMethod(
	FindingMethodType.SEARCH,
	FindingMethodSubtype.RECENT_SEARCH_TERM
);
export const buildGTMFindingMethodSearchDrop = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SEARCH_DROP);
export const buildGTMFindingMethodCategoryDrop = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.CATEGORY_DROP);
export const buildGTMFindingMethodCollectionDrop = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.COLLECTION_DROP);
export const buildGTMFindingMethodNonstockDrop = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.NONSTOCK_DROP);
export const buildGTMFindingMethodProductListDrop = buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.PRODUCT_LIST);
export const buildGTMFindingMethodDynamicRecommendation = buildGTMFindingMethod(
	FindingMethodType.RECOMMENDATIONS,
	FindingMethodSubtype.DYNAMIC_RECOMMENDATION
);
